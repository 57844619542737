import Layout from '@/layout'

const tableRouter = {
  path: '/system',
  name: 'System',
  component: Layout,
  meta: {
    title: '系统管理',
    icon: 'el-icon-s-management'
  },
  children: [{
    path: 'area-manage',
    name: 'AreaManage',
    component: resolve => require(['@/views/system/area-manage'], resolve),
    meta: {title: '片区管理'}
  },
    {
      path: 'notify-manage',
      name: 'NotifyManage',
      component: resolve => require(['@/views/system/notify-manage'], resolve),
      meta: { title: '通知管理' }
    },
    {
      path: 'student-manage',
      name: 'StudentManage',
      component: resolve => require(['@/views/system/student-manage'], resolve),
      meta: {title: '学员管理'}
    },
    {
      path: 'menu',
      name: 'MenuManage',
      component: resolve => require(['@/views/system/menu'], resolve),
      meta: {title: '菜单管理', noCache: true}
    },
    {
      path: 'operateRecord',
      name: 'operateRecord',
      component: resolve => require(['@/views/system/operateRecord'], resolve),
      meta: {title: '操作日志', noCache: true}
    },
    {
      path: 'staff-manage',
      name: 'StaffManage',
      component: resolve => require(['@/views/system/staff-manage'], resolve),
      meta: {title: '员工管理'}
    },
    {
      path: 'account',
      name: 'Account',
      component: resolve => require(['@/views/system/account'], resolve),
      meta: {title: '账号管理', noCache: true}
    },
    {
      path: 'userType',
      name: 'UserType',
      component: resolve => require(['@/views/system/userType'], resolve),
      meta: {title: '用户类型管理', noCache: true}
    },
    {
      path: 'rtmlog',
      name: 'rtmlog',
      component: resolve => require(['@/views/system/rtmlog'], resolve),
      meta: {title: 'RTM日志', noCache: true}
    },

    {
      path: 'role-manage',
      name: 'RoleManage',
      component: resolve => require(['@/views/system/role-manage'], resolve),
      meta: {title: '角色管理', noCache: true}
    },

    {
      path: 'data-sync',
      name: 'DataSync',
      component: resolve => require(['@/views/system/data-sync'], resolve),
      meta: {title: '数据同步'}
    },
    {
      path: 'campus-manage',
      name: 'CampusManage',
      component: resolve => require(['@/views/system/campus-manage'], resolve),
      meta: {title: '校区管理'}
    },
    {
      path: 'cost-manage',
      name: 'CostManage',
      component: resolve => require(['@/views/system/cost-manage'], resolve),
      meta: {title: '课系费管理'}
    },
    {
      path: 'level-manage',
      name: 'LevelManage',
      component: resolve => require(['@/views/system/level-manage'], resolve),
      meta: {title: '教师级别管理'}
    },
    {
      path: 'item-management',
      name: 'ItemManagement',
      component: resolve => require(['@/views/system/item-management'], resolve),
      meta: {title: '仓库物品管理'}
    },
    {
      path: 'goods-flowing',
      name: 'GoodsFlowing',
      component: resolve => require(['@/views/system/goods-flowing'], resolve),
      meta: {title: '物品出入库记录'}
    },
    {
      path: 'charging-course',
      name: 'ChargingCourse',
      component: resolve => require(['@/views/system/charging-course'], resolve),
      meta: {title: '收费课程管理', noCache: true}
    },
    {
      path: 'sell-course',
      name: 'SellCourse',
      component: resolve => require(['@/views/system/sell-course'], resolve),
      meta: {title: '销售课程包管理', noCache: true}
    },
    {
      path: 'class-voucher',
      name: 'ClassVoucher',
      component: resolve => require(['@/views/system/class-voucher'], resolve),
      meta: {title: '课时抵扣券类型'}
    },
    {
      path: 'student-voucher',
      name: 'StudentVoucher',
      component: resolve => require(['@/views/system/student-voucher'], resolve),
      meta: {title: '学员课时券管理', noCache: true}
    },
    {
      path: 'coefficient-list',
      name: 'coefficientList',
      component: resolve => require(['@/views/dollars/coefficient-list'], resolve),
      meta: {title: '设置班容系数', noCache: true}
    },
  ]
}

export default tableRouter
