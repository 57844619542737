import Layout from '@/layout'

const merchantAccountRouter = [
    {
        path: '/merchantAccount',
        name: 'MerchantAccount',
        component: Layout,
        redirect: '/merchantAccount/merchantAccount',
        meta: {
            title: '商户账户管理',
            icon: 'el-icon-s-comment'
        },
        children: [{
                path: 'merchantInfo',
                name: 'MerchantInfo',
                component: resolve => require(['@/views/merchantAccount/merchantInfo'], resolve),
                meta: { title: '商户信息', noCache: true }
            },
            {
                path: 'merchantAccount',
                name: 'MerchantAccount',
                component: resolve => require(['@/views/merchantAccount/index'], resolve),
                meta: { title: '校区管理' , noCache: true }
            },
            {
              path: 'merchantAccountDetail',
              name: 'merchantAccountDetail',
              component: resolve => require(['@/views/merchantAccount/merchantAccountDetail'], resolve),
              meta: { title: '校区详情' , noCache: true }
            },
            {
              path: 'purchaseCourse',
              name: 'purchaseCourse',
              component: resolve => require(['@/views/merchantAccount/purchaseCourse'], resolve),
              meta: { title: '购买课时' , noCache: true }
            },
            {
                path: 'teacher',
                name: 'Teacher',
                component: resolve => require(['@/views/merchantAccount/teacher'], resolve),
                meta: { title: '商户教师账户管理' , noCache: true }
            },
            {
                path: 'ministrators',
                name: 'Ministrators',
                component: resolve => require(['@/views/merchantAccount/ministrators'], resolve),
                meta: { title: '管理员管理' , noCache: true }
            }
        ]
    },
]
export default merchantAccountRouter
