import Layout from '@/layout'

const tableRouter =   {
  path: '/senate',
  name: 'Senate',
  component: Layout,
  meta: {
    title: '教务管理',
    icon: 'el-icon-notebook-2'
  },
  children: [
    {
      path: 'reportTemplate',
      name: 'reportTemplate',
      component: resolve => require(['@/views/senate/reportTemplate'], resolve),
      meta: { title: '报告模板' }
    },
    {
      path: 'classroom-manage',
      name: 'ClassRoomManage',
      component: resolve => require(['@/views/senate/classroom-manage'], resolve),
      meta: { title: '教室管理' }
    },
    {
      path: 'class-manage',
      name: 'ClassManage',
      component: resolve => require(['@/views/senate/class-manage'], resolve),
      meta: { title: '班级管理' }
    },
    {
      path: 'live-room',
      name: 'LiveRoom',
      component: resolve => require(['@/views/senate/live-room'], resolve),
      meta: { title: '直播间管理' }
    },
    {
      path: 'class-detail',
      name: 'ClassDetail',
      component: resolve => require(['@/views/senate/class-detail'], resolve),
      meta: { title: '班级详情', noCache: true }
    },
    {
      path: 'submit_list',
      name: 'SubmitList',
      component: resolve => require(['@/views/senate/submit_list'], resolve),
      meta: { title: '提交记录列表', noCache: true }
    },
    {
      path: 'submit_python',
      name: 'SubmitPython',
      component: resolve => require(['@/views/senate/submit_python'], resolve),
      meta: { title: 'python提交记录', noCache: true }
    },
    {
      path: 'contest-rank',
      name: 'ContestRank',
      component: resolve => require(['@/views/senate/contest-rank'], resolve),
      meta: { title: '排行榜', noCache: true }
    },
    {
      path: 'class-quesbank',
      name: 'ClassQuesbank',
      component: resolve => require(['@/views/senate/class-quesbank'], resolve),
      meta: { title: '班级题库' }
    },
    {
      path: 'class-quesbank-detail',
      name: 'ClassQuesbankDetail',
      component: resolve => require(['@/views/senate/class-quesbank-detail'], resolve),
      meta: { title: '题库详情', noCache: true }
    },
    {
      path: 'preview-course',
      name: 'PreviewCourse',
      component: resolve => require(['@/views/senate/preview-course'], resolve),
      meta: { title: '排课详情', noCache: true }
    },
    {
      path: 'classroom-task',
      name: 'ClassroomTask',
      component: resolve => require(['@/views/senate/classroom-task'], resolve),
      meta: { title: '课堂任务', noCache: true }
    },
    {
      path: 'lessons-audit',
      name: 'LessonsAudit',
      component: resolve => require(['@/views/senate/lessons-audit'], resolve),
      meta: { title: '补课审核' }
    },
    {
      path: 'listenStu-manage',
      name: 'ListenStuManage',
      component: resolve => require(['@/views/senate/listenStu-manage'], resolve),
      meta: { title: '试听管理' }
    }
  ]
}

export default tableRouter
