var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "uploader-file", attrs: { status: _vm.status } },
    [
      _vm._t(
        "default",
        function () {
          return [
            _c("div", {
              staticClass: "uploader-file-progress",
              class: _vm.progressingClass,
              style: _vm.progressStyle,
            }),
            _c("div", { staticClass: "uploader-file-info" }, [
              _c("div", { staticClass: "uploader-file-name" }, [
                _vm._v(_vm._s(_vm.file.name)),
              ]),
              _c("div", { staticClass: "uploader-file-size" }, [
                _vm._v("大小：" + _vm._s(_vm.formatedSize)),
              ]),
              _c("div", { staticClass: "uploader-file-meta" }),
              _c("div", { staticClass: "uploader-file-status" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.status !== "uploading",
                        expression: "status !== 'uploading'",
                      },
                    ],
                  },
                  [_vm._v(_vm._s(_vm.statusText))]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.status === "uploading",
                        expression: "status === 'uploading'",
                      },
                    ],
                  },
                  [
                    _c("span", [
                      _vm._v("进度：" + _vm._s(_vm.progressStyle.progress)),
                    ]),
                    _c("em", { staticStyle: { "margin-left": "5px" } }, [
                      _vm._v("速度：" + _vm._s(_vm.formatedAverageSpeed)),
                    ]),
                    _c("i", { staticStyle: { "margin-left": "5px" } }, [
                      _vm._v("剩余时间：" + _vm._s(_vm.formatedTimeRemaining)),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "uploader-file-actions" }, [
                _c("span", {
                  staticClass: "uploader-file-pause",
                  on: { click: _vm.pause },
                }),
                _c(
                  "span",
                  {
                    staticClass: "uploader-file-resume",
                    on: { click: _vm.resume },
                  },
                  [_vm._v("️")]
                ),
                _c("span", {
                  staticClass: "uploader-file-retry",
                  on: { click: _vm.retry },
                }),
                _c("span", {
                  staticClass: "uploader-file-remove",
                  on: { click: _vm.remove },
                }),
              ]),
            ]),
          ]
        },
        {
          file: _vm.file,
          list: _vm.list,
          status: _vm.status,
          paused: _vm.paused,
          error: _vm.error,
          response: _vm.response,
          averageSpeed: _vm.averageSpeed,
          formatedAverageSpeed: _vm.formatedAverageSpeed,
          currentSpeed: _vm.currentSpeed,
          isComplete: _vm.isComplete,
          isUploading: _vm.isUploading,
          size: _vm.size,
          formatedSize: _vm.formatedSize,
          uploadedSize: _vm.uploadedSize,
          progress: _vm.progress,
          progressStyle: _vm.progressStyle,
          progressingClass: _vm.progressingClass,
          timeRemaining: _vm.timeRemaining,
          formatedTimeRemaining: _vm.formatedTimeRemaining,
          type: _vm.type,
          extension: _vm.extension,
          fileCategory: _vm.fileCategory,
        }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }