var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tags-view-container",
      attrs: { id: "tags-view-container" },
    },
    [
      _c("div", { staticClass: "pointer app-fold mt-04" }, [
        _c("i", {
          class: {
            "el-icon-s-unfold": _vm.opened,
            "el-icon-s-fold": !_vm.opened,
          },
          on: {
            click: function ($event) {
              return _vm.toggleOpen()
            },
          },
        }),
      ]),
      _c(
        "el-dropdown",
        {
          staticClass: "pointer mt-04",
          staticStyle: { "margin-left": "12px" },
          attrs: { placement: "bottom" },
        },
        [
          _c("span", [
            _c("i", {
              staticClass: "el-icon-circle-close",
              staticStyle: { "font-size": "17px", "font-weight": "bold" },
            }),
          ]),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c(
                "el-dropdown-item",
                {
                  nativeOn: {
                    click: function ($event) {
                      return _vm.closeOthersTags.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("关闭其他")]
              ),
              _c(
                "el-dropdown-item",
                {
                  nativeOn: {
                    click: function ($event) {
                      return _vm.closeAllTags.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("关闭全部")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "scroll-pane",
        { ref: "scrollPane", staticClass: "tags-view-wrapper" },
        _vm._l(_vm.visitedViews, function (tag) {
          return _c(
            "router-link",
            {
              key: tag.path,
              ref: "tag",
              refInFor: true,
              staticClass: "tags-view-item",
              class: _vm.isActive(tag) ? "active" : "",
              attrs: {
                to: {
                  path: tag.path,
                  query: tag.query,
                  fullPath: tag.fullPath,
                },
                tag: "span",
              },
              nativeOn: {
                mouseup: function ($event) {
                  if ("button" in $event && $event.button !== 1) return null
                  !_vm.isAffix(tag) ? _vm.closeSelectedTag(tag) : ""
                },
                contextmenu: function ($event) {
                  $event.preventDefault()
                  return _vm.openMenu(tag, $event)
                },
              },
            },
            [
              _vm._v(" " + _vm._s(tag.meta.title) + " "),
              !_vm.isAffix(tag)
                ? _c("span", {
                    staticClass: "el-icon-close",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.closeSelectedTag(tag)
                      },
                    },
                  })
                : _vm._e(),
            ]
          )
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "app-personal pointer" },
        [
          _c("span", {
            staticClass: "pointer el-icon-full-screen",
            on: { click: _vm.toggleFull },
          }),
          _c(
            "el-dropdown",
            { staticStyle: { color: "#c8c7c7" }, attrs: { trigger: "click" } },
            [
              _c("div", { staticStyle: { "font-size": "16px" } }, [
                _c("span", [
                  _vm._v("Hi，" + _vm._s(_vm.name)),
                  _c("i", {
                    staticClass: "el-icon-caret-bottom personal-bottom",
                  }),
                ]),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { solt: "dropdown" } },
                [
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.showPersonal.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-s-custom" }),
                      _vm._v("个人中心 "),
                    ]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.exit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-switch-button" }),
                      _vm._v("退出登录 "),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("user-personal", {
        attrs: { show: _vm.personalDialog },
        on: {
          close: function ($event) {
            _vm.personalDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }