var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.support,
          expression: "!support",
        },
      ],
      staticClass: "uploader-unsupport",
    },
    [
      _vm._t("default", function () {
        return [_vm._m(0)]
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        " Your browser, unfortunately, is not supported by Uploader.js. The library requires support for "
      ),
      _c("a", { attrs: { href: "http://www.w3.org/TR/FileAPI/" } }, [
        _vm._v("the HTML5 File API"),
      ]),
      _vm._v(" along with "),
      _c(
        "a",
        {
          attrs: {
            href: "http://www.w3.org/TR/FileAPI/#normalization-of-params",
          },
        },
        [_vm._v("file slicing")]
      ),
      _vm._v(". "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }