var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "uploader" },
    [
      _vm._t(
        "default",
        function () {
          return [
            _c("uploader-unsupport"),
            _c(
              "uploader-drop",
              [
                _c("p", [_vm._v("Drop files here to upload or")]),
                _c("uploader-btn", [_vm._v("select files")]),
                _c("uploader-btn", { attrs: { directory: true } }, [
                  _vm._v("select folder"),
                ]),
              ],
              1
            ),
            _c("uploader-list"),
          ]
        },
        { files: _vm.files, fileList: _vm.fileList, started: _vm.started }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }