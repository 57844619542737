import Layout from '@/layout'

const operationRouter = [
    {
        path: '/operation',
        name: 'operation',
        component: Layout,
        redirect: '/operation/operation',
        meta: {
            title: '运营中心',
            icon: 'el-icon-s-comment'
        },
        children: [
            {
                path: 'operation',
                name: 'operation',
                component: resolve => require(['@/views/operation/index'], resolve),
                meta: { title: '题集模板' , noCache: true}
          },
          {
                path: 'operationDetail',
                name: 'operationDetail',
                component: resolve => require(['@/views/operation/detail'], resolve),
                meta: { title: '模板详情' , noCache: true}
            }
        ]
    }
]
export default operationRouter