<template>
  <div class="sideBar" id="domSideBar">
    <div class="logo_container">
      <img src="../../../assets/images/app_logo.png" v-show="!opened">
      <img src="../../../assets/images/sm_logo.png" v-show="opened">
    </div>
    <el-scrollbar>
      <el-menu
        :default-active="activeMenu"
        class="el-menu-vertical-demo"
        background-color="#3a3f51"
        text-color="#b5b6bd"
        active-text-color="#f5c319"
        mode="vertical"
        :collapse-transition="false"
        :collapse="opened"
      >
        <sidebar-item
          v-for="value in routes"
          :key="value.path"
          :item="value"
          :basePath="value.path"
        ></sidebar-item>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
// import { routerjson } from '../../../api/router'
import SidebarItem from './SideBarItem'
import { mapGetters } from 'vuex'

export default {
  components: { SidebarItem },
  data () {
    return {
      routes: []
      // routes: routerjson
    }
  },
  computed: {
    ...mapGetters(['opened']),
    activeMenu () {
      return this.$route.path
    }
  },
  created () {
    this.routes = JSON.parse(localStorage.getItem('routerList'))
  }
}
</script>

<style lang="scss" scoped>
.logo_container {
  padding-top: 10px;
  height: 60px;
  // display: flex;
  // justify-content: center;
  margin-left: 15px;
}
</style>
