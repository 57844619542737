import Layout from '@/layout'

const worksRouter = {
    path: '/works',
    name: 'Works',
    component: Layout,
    meta: {
        title: '作品管理',
        icon: 'el-icon-wallet'
    },
    children: [
      {
        path: 'worksAdministration',
        name: 'worksAdministration',
        component: resolve => require(['@/views/works/worksAdministration'], resolve),
        meta: { title: '作品管理', noCache: true }
      }
    ]
}

export default worksRouter
