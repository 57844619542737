import Layout from '@/layout'

const tableRouter =   {
  path: '/research',
  name: 'Research',
  component: Layout,
  meta: {
    title: '课程研发',
    icon: 'el-icon-wallet'
  },
  children: [
    {
      path: 'knowledge',
      name: 'knowledge',
      component: resolve => require(['@/views/research/knowledge'], resolve),
      meta: { title: '知识点管理', noCache: true }
    },
    {
      path: 'question-manage',
      name: 'QuestionManage',
      component: resolve => require(['@/views/research/question-manage'], resolve),
      meta: { title: '问题管理', noCache: true }
    },
    {
      path: 'operateRecord',
      name: 'operateRecord',
      component: resolve => require(['@/views/system/operateRecord'], resolve),
      meta: { title: '操作日志', noCache: true }
    },
    {
      path: 'mouldManage',
      name: 'mouldManage',
      component: resolve => require(['@/views/research/mouldManage'], resolve),
      meta: { title: 'scratch模板管理', noCache: true }
    },
    {
      path: 'mouldPygame',
      name: 'mouldPygame',
      component: resolve => require(['@/views/research/pygame-template'], resolve),
      meta: { title: 'Pygame预置代码', noCache: true }
    },
    {
      path: 'scratchjrMould',
      name: 'scratchjrMould',
      component: resolve => require(['@/views/ScratchJr/template'], resolve),
      meta: { title: 'scratchJr模板管理', noCache: true }
    },

    {
      path: 'question-records',
      name: 'QuestionRecords',
      component: resolve => require(['@/views/research/question-records'], resolve),
      meta: { title: '提交记录', noCache: true }
    },
    {
      path: 'quesbank-manage',
      name: 'QuesbankManage',
      component: resolve => require(['@/views/research/quesbank-manage'], resolve),
      meta: { title: '题库管理' }
    },
    {
      path: 'student-tag',
      name: 'studentTag',
      component: resolve => require(['@/views/research/student-tag'], resolve),
      meta: { title: '学员标签管理' }
    },
    {
      path: 'tag-manage',
      name: 'TsgManage',
      component: resolve => require(['@/views/research/tag-manage'], resolve),
      meta: { title: '标签管理' }
    },
    {
      path: 'contest-manage',
      name: 'contestManage',
      component: resolve => require(['@/views/research/contest-manage'], resolve),
      meta: { title: '竞赛管理' }
    },
    {
      path: 'contest-detail',
      name: 'ContestDetail',
      component: resolve => require(['@/views/research/contest-detail'], resolve),
      meta: { title: '竞赛详情', noCache: true }
    },
    {
      path: 'curriculum-manage',
      name: 'CurriculumManage',
      component: resolve => require(['@/views/research/curriculum-manage'], resolve),
      meta: { title: '课程管理' }
    },
    {
      path: 'curriculum-category',
      name: 'CurriculumCategory',
      component: resolve => require(['@/views/research/curriculum-category'], resolve),
      meta: { title: '课程系列', noCache: true  }
    },
    {
      path: 'course-category',
      name: 'CourseCategory',
      component: resolve => require(['@/views/research/course-category'], resolve),
      meta: { title: '课程分类', noCache: true  }
    },
    {
      path: 'curriculum-details',
      name: 'CurriculumDetails',
      component: resolve => require(['@/views/research/curriculum-details'], resolve),
      meta: { title: '课程详情', noCache: true }
    },
    {
      path: 'curriculum-details-train',
      name: 'CurriculumDetailsTrain',
      component: resolve => require(['@/views/research/curriculum-details-train'], resolve),
      meta: { title: '课程详情', noCache: true }
    },
    {
      path: 'testquestion-manage',
      name: 'TestquestionManage',
      component: resolve => require(['@/views/research/testquestion-manage'], resolve),
      meta: { title: '试题管理' }
    },
    {
      path: 'exam-manage',
      name: 'ExamManage',
      component: resolve => require(['@/views/research/exam-manage'], resolve),
      meta: { title: '试卷管理' }
    },
    {
      path: 'redetermine-subject',
      name: 'RedetermineSubject',
      component: resolve => require(['@/views/research/redetermine-subject'], resolve),
      meta: { title: 'OJ-重判题目' }
    }
  ]
}

export default tableRouter
