import Layout from '@/layout'

const trainRouter = {
    path: '/train',
    name: 'Train',
    component: Layout,
    meta: {
        title: '培训管理',
        icon: 'el-icon-wallet'
    },
    children: [
      {
        path: 'course',
        name: 'TrainCourse',
        component: resolve => require(['@/views/train/course'], resolve),
        meta: { title: '培训课程', noCache: true }
      },{
        path: 'peixunmgr',
        name: 'peixunmgr',
        component: resolve => require(['@/views/train/peixunmgr'], resolve),
        meta: { title: '培训管理', noCache: true }
      }
    ]
}

export default trainRouter
