var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "uploader-list" },
    [
      _vm._t(
        "default",
        function () {
          return [
            _c(
              "ul",
              _vm._l(_vm.fileList, function (file) {
                return _c(
                  "li",
                  { key: file.id },
                  [_c("uploader-file", { attrs: { file: file, list: true } })],
                  1
                )
              }),
              0
            ),
          ]
        },
        { fileList: _vm.fileList }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }