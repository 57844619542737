import Layout from '@/layout'

import educationRouter from './education'

const courseRouter = [
    // {
    //     path: '/coursetime',
    //     name: 'Coursetime',
    //     component: Layout,
    //     redirect: '/coursetime/coursetime',
    //     children: [{
    //         path: 'coursetime',
    //         component: resolve => require(['@/views/course/courseTimes'], resolve),
    //         meta: { title: '课时统计', icon: 'el-icon-s-data', affix: true }
    //     }]
    // },
    {
        path: '/coursetime',
        name: 'Coursetime',
        component: Layout,
        redirect: '/coursetime/coursetime',
        meta: {
            title: '课时统计',
            icon: 'el-icon-s-comment'
        },
        children: [
            // {
            //     path: 'coursetime',
            //     name: 'coursetime',
            //     component: resolve => require(['@/views/course/courseManageTimes'], resolve),
            //     meta: { title: '商户课时统计', icon: 'el-icon-s-data', affix: true }
            // },
            {
                path: 'coursetime',
                component: resolve => require(['@/views/course/courseTimes'], resolve),
                meta: { title: '课时统计', icon: 'el-icon-s-data', affix: true }
            },
            {
                path: 'curriculumStatistics',
                name: 'curriculumStatistics',
                component: resolve => require(['@/views/course/curriculumStatistics'], resolve),
                meta: { title: '商户课程统计' , noCache: true}
            },
            {
                path: 'studentStatistics',
                name: 'studentStatistics',
                component: resolve => require(['@/views/course/studentStatistics'], resolve),
                meta: { title: '商户学学员统计' , noCache: true}
            },
            {
                path: 'classStatistics',
                name: 'classStatistics',
                component: resolve => require(['@/views/course/classStatistics'], resolve),
                meta: { title: '商户班级统计' , noCache: true}
            }
        ]
    },
    {
        path: '/coursecenter',
        name: 'Coursecenter',
        component: Layout,
        redirect: '/coursecenter/management',
        meta: {
            title: '课程中心',
            icon: 'el-icon-s-comment'
        },
        children: [{
                path: 'management',
                name: 'Management',
                component: resolve => require(['@/views/course/courseManagement'], resolve),
                meta: { title: '课程管理', noCache: true }
            },
            {
                path: 'student',
                name: 'Student',
                component: resolve => require(['@/views/course/courseStudent'], resolve),
                meta: { title: '学员课程' }
            },
            {
                path: 'spend',
                name: 'Spend',
                component: resolve => require(['@/views/course/courseSpend'], resolve),
                meta: { title: '学员课消' }
            }
        ]
    },
    ...educationRouter,
]
export default courseRouter