<template>
  <div id="app">
    <router-view  v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
export default {
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },

  mounted() {
    document.addEventListener('visibilitychange', function() {
      if (!document.hidden) {
        if(sessionStorage.getItem("token_manage") != "null" && sessionStorage.getItem("token_manage") != null && sessionStorage.getItem("token_manage") != localStorage.getItem("token_manage")) {
          location.reload();
        }
      }
    });

  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  }
}

</script>
<style lang="scss">
html, body, #app {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
.text-oneline{
  display: inline-block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow:ellipsis;
}
.edit-style {
  .el-dialog__header {
    height: 10px;
    padding: 0 !important;
    padding-bottom: 0 !important;
    position: fixed;
    right: 0;
    .el-dialog__headerbtn {
      position: absolute;
      right: 50px;
      top: 12px;
      color: #000;
      z-index: 99999;
    }
  }
  .el-icon-close:before{
    content: 'X';
  }
  .el-dialog__body {
    padding: 0 !important;
    width: calc(100vw);
    height: calc(100vh);
    overflow: hidden;
  }
}
</style>
