import $axios from '@/utils/request'

// 获取用户信息
export function getMyInfo(data) {
    const url = '/sso/user/myInfo'
    return $axios.fInfoGet(url, data)
}

// 用户修改密码
export function userEditPwd(data) {
    const url = '/sso/user/editPwd'
    return $axios.fInfoPost(url, data)
}
// 修改密码
export function ssoEditPwd(data) {
    const url = '/sso/user/editPwd'
    return $axios.fPost(url, data)
}
// passwordCheck

export function passwordCheck(data) {
    const url = '/user/checkPass'
    return $axios.fLoginPost(url, data)
}

// 用户增加常用时间段
export function addUserPeriod(data) {
    const url = '/user/period/addUserPeriod'
    return $axios.fPost(url, data)
}

// 用户删除常用时间段
export function deleteUserPeriod(data) {
    const url = '/user/period/deleteUserPeriod'
    return $axios.fGet(url, data)
}

// 用户常用时间段列表
export function listTeacherLevel(data) {
    const url = '/user/period/listUserPeriodByUser'
    return $axios.fPost(url, data)
}

//视频管理模块 中根据id获取创建人姓名
export function getNameByUserId(data) {
    const url = '/user/getNameByUserId'
    return $axios.fGet(url, data)
}

// 学生列表 - 校区
export function getListStudentForCampus(data) {
    const url = '/user/listStudentForCampus'
    return $axios.fPost(url, data)
}