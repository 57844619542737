import Layout from '@/layout'

const prepareLessonRouter = [{
  path: '/prepareLesson',
  name: 'prepareLesson',
  component: Layout,
  redirect: '/prepareLesson/prepareLessonCenters',
  meta: {
    title: '备课管理',
    icon: 'el-icon-s-comment'
  },
  children: [
    {
      path: 'prepareLessonCenter',
      name:'prepareLessonCenter',
      component: resolve => require(['@/views/prepareLesson/prepareLessonCenter'], resolve),
      meta: {
        title: '备课中心',
        noCache: true
      }
    }, 
    {
      path: 'merchantCourseDetails',
      name:'merchantCourseDetails',
      component: resolve => require(['@/views/prepareLesson/merchantCourseDetails'], resolve),
      meta: {
        title: '商户课程',
        noCache: true
      }
    }, 
    {
      path: 'teacherDetails',
      name:'teacherDetails',
      component: resolve => require(['@/views/prepareLesson/teacherDetails'], resolve),
      meta: {
        title: '商户课程',
        noCache: true
      }
    }, 
]
}]
export default prepareLessonRouter
