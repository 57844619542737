import Vue from 'vue'

import Element from 'element-ui'
import './style/element-variables.scss'

import './plugins/tinymce'

import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import './assets/font/iconfont.css' // 引入iconfont图标库
import './utils/request'
import './utils/function'
import './utils/directives'
import uploader from '../src/index'

import outside from './directives/outside'
import watermark from '@/utils/watermark.js'
Vue.prototype.$watermark = watermark
import VueClipboard from 'vue-clipboard2' // vue剪切板功能插件
import 'highlight.js/styles/googlecode.css'
import animate from 'animate.css'

import $ from 'jquery'
import './filters'
import './assets/font/iconfont'
import IconSvg from '@/components/IconSvg'

import './style/index.scss' // global css
import LuckDraw from 'vue-luck-draw'

import ElTableBar from 'el-table-bar-base'
import 'el-table-bar-base/lib/ElTableBar.css'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
// 引入echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

// https://github.com/surmon-china/vue-video-player
// https://www.jianshu.com/p/532fc1d8c90c
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)

Vue.use(ElTableBar)
Vue.directive('outside', outside)
import animated from 'animate.css';

//哨兵监测性能
// import * as Sentry from "@sentry/browser";
// import { Vue as VueIntegration } from "@sentry/integrations";
// import { Integrations } from "@sentry/tracing";

// Sentry.init({
//   dsn: "https://8d919093e08942f5bc0fe71c892c529a@o476164.ingest.sentry.io/5515282", // 这里是你的 dsn 地址，注册完就有
//   integrations: [
//     new VueIntegration({
//       Vue,
//       tracing: true,
//       logErrors: true
//     }),
//     new Integrations.BrowserTracing(),
//   ],
//
//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });


Vue.use(animated)
Vue.use(LuckDraw)

Vue.config.productionTip = false

Vue.prototype.$axios = axios
axios.defaults.withCredentials = true

Vue.component('icon-svg', IconSvg)
Vue.use(uploader)

Vue.use(Element, {
    size: 'medium' // set element-ui default size
})
Vue.use(VueClipboard)

window.vm = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')