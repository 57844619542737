/**
 * 消息提示
 * 5秒后消失
 * @param msg 提示内容
 * @param type 0，成功，默认；1失败；2，警告
 */
window.$msg = function (msg = '操作成功', type = 0) {
  let map = {
    0: 'success',
    1: 'error',
    2: 'warning'
  }

  window.vm.$message({
    showClose: true,
    message: msg,
    type: map[type],
    duration: type === 1 ? 1000 * 5 : 1000 * 2
  })
}

/**
 * 确认框
 * @param msg 提示内容
 * @param fn 确认后的操作，默认不作操作
 */

window.$alert = function (msg = '操作成功', fn = () => {}) {
  window.vm.$alert(msg, '请注意', {
    confirmButtonText: '确定',
    callback: fn
  })
}

/**
 * 格式化货币
 * @param number
 * @param places
 * @param symbol
 * @param thousand
 * @param decimal
 * @returns {string}
 */
window.formatMoney = function (number, places, symbol, thousand, decimal) {
  number = number || 0
  // 保留的小位数 可以写成 formatMoney(542986,3) 后面的是保留的小位数，否则默 认保留两位
  places = !isNaN(places = Math.abs(places)) ? places : 2
  // symbol表示前面表示的标志是￥ 可以写成 formatMoney(542986,2,"$")
  symbol = symbol !== undefined ? symbol : '￥'
  // thousand表示每几位用,隔开,是货币标识
  thousand = thousand || ','
  // decimal表示小数点
  decimal = decimal || '.'
  // negative表示如果钱是负数有就显示“-”如果不是负数 就不显示负号
  // i表示处理过的纯数字
  let negative
  negative = number < 0 ? '-' : ''
  let i
  i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + ''
  let j
  j = (j = i.length) > 3 ? j % 3 : 0
  return symbol + negative + (j ? i.substr(0, j) + thousand : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '￥1' + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : '')
}

window.uuid = function() {
  var s = []
  var hexDigits = '0123456789abcdef'
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-'
  var uuid = s.join('')
  return uuid
}