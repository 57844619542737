<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
    append-to-body
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>个人中心</span>
    </div>
    <el-form :model="formData" ref="formData" label-width="100px">
      <el-form-item label="用户名：">
        <span>{{formData.loginName}}</span>
      </el-form-item>
      <el-form-item label="姓名：">
        <span>{{formData.name}}</span>
      </el-form-item>
      <el-form-item label="密码：">
        <el-button @click="changePass" size="small" type="info">修改密码</el-button>
      </el-form-item>
    </el-form>

    <!-- 修改密码 -->
    <change-pass :show="changePassDialog" @close="changePassDialog=false" @savePass="savePass"></change-pass>
  </el-dialog>
</template>

<script>
import ChangePass from '../ChangePass'
import { getMyInfo, userEditPwd ,passwordCheck} from '@/api/user'

export default {
  components: {
    ChangePass
  },
  props: {
    show: {
      default: false,
      type: Boolean
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data () {
    return {
      formData: {},
      changePassDialog: false
    }
  },
  methods: {
    openDialog () {
      this.getUserInfo()
    },

    // 获取本人的用户信息
    async getUserInfo () {
      const res = await getMyInfo()
      this.formData = Object.assign({}, res.body)
    },

    // 关闭
    close () {
      this.$emit('close')
    },

    // 修改密码
    changePass () {
      this.changePassDialog = true
    },

    // 保存密码
    async savePass (data) {
      let passData = {
        pwdOld: data.originalPass,
        pwdNew: data.newPass,
      }
      // 密码校验
      const formdate = new FormData();
      formdate.append('password',  data.newPass,)
      const res = await passwordCheck(formdate)
      if(!res.body){
        window.$msg('当前新密码不符合密码规范，请重新填写密码', 2)
        return
      }
      await userEditPwd({...passData})
      this.$store.dispatch('user/loginOut').then(() => {
        window.$msg('密码修改成功,请重新登录', 2)
        this.close()
        this.changePassDialog = false
      })
      // fPost("/user/editPwd", { ...passData })
      //   .then(res => {
      //     if (res.state === "success") {
      //       $msg("密码修改成功");
      //       this.close();
      //       this.changePassDialog = false;
      //       clearCookie("token");
      //       clearCookie("token_manage");
      //       localStorage.clear();
      //       this.$router.push("/admin/login");
      //     } else {
      //       $msg(res.errMsg, 2);
      //     }
      //   })
      //   .catch(err => {
      //     console.log("err", err);
      //     $msg("请求失败", 1);
      //   });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../style/dialog.scss";
</style>
