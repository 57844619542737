import Layout from '@/layout'

const educationRouter = [{
        path: '/education',
        name: 'Education',
        component: Layout,
        redirect: '/education/student',
        meta: {
            title: '教务中心',
            icon: 'el-icon-s-comment'
        },
        children: [{
                path: 'student',
                name: 'Student',
                component: resolve => require(['@/views/member/student'], resolve),
                meta: { title: '学员管理', noCache: true }
            },
            {
                path: 'teacher',
                name: 'Teacher',
                component: resolve => require(['@/views/member/teacher'], resolve),
                meta: { title: '教师管理', noCache: true }
            },
            {
                path: 'classmanage',
                name: 'ClassManage',
                component: resolve => require(['@/views/merchantClass/classmanage'], resolve),
                meta: { title: '班级管理', noCache: true }
            },
            {
                path: 'class-detail',
                name: 'ClassDetail',
                component: resolve => require(['@/views/merchantClass/class-detail'], resolve),
                meta: { title: '班级详情', noCache: true }
            },
        ]
    },
    {
        path: '/member',
        name: 'Member',
        component: Layout,
        redirect: '/member/student',
        meta: {
            title: '成员管理',
            icon: 'el-icon-s-comment'
        },
        children: [{
                path: 'student',
                name: 'Student',
                component: resolve => require(['@/views/member/student'], resolve),
                meta: { title: '学员管理', noCache: true }
            },
            {
                path: 'teacher',
                name: 'Teacher',
                component: resolve => require(['@/views/member/teacher'], resolve),
                meta: { title: '教师管理' }
            }
        ]
    },
    {
        path: '/course',
        name: 'Course',
        component: Layout,
        redirect: '/course/signup',
        meta: {
            title: '课程管理',
            icon: 'el-icon-s-comment'
        },
        children: [{
                path: 'signup',
                name: 'Signup',
                component: resolve => require(['@/views/course/signup'], resolve),
                meta: { title: '学员报名', noCache: true }
            },
            {
                path: 'statistics',
                name: 'Statistics',
                component: resolve => require(['@/views/course/statistics'], resolve),
                meta: { title: '学员使用统计' }
            }
        ]
    },
    {
        path: '/merchantClass',
        name: 'merchantClass',
        component: Layout,
        redirect: '/merchantClass/classmanage',
        meta: {
            title: '班排管理',
            icon: 'el-icon-s-comment'
        },
        children: [{
                path: 'classmanage',
                name: 'ClassManage',
                component: resolve => require(['@/views/merchantClass/classmanage'], resolve),
                meta: { title: '班级管理', noCache: true }
            },
            {
                path: 'class-detail',
                name: 'ClassDetail',
                component: resolve => require(['@/views/merchantClass/class-detail'], resolve),
                meta: { title: '班级详情', noCache: true }
            },
        ]
    }
]
export default educationRouter
